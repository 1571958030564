import { Button, Col, Form, Input, notification, Row } from 'antd';
import { Select } from 'antd';
import { useCallback, useState } from 'react';

import { MaskedInput } from 'antd-mask-input';

import { CloseCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import Person from '../../common/interfaces/Person';

interface Props {
  initialValues: Person[] | undefined;
  keyName: 'guarantors' | 'tenants' | 'locators' | 'witness';
  handleSaveForm: (data: { [key: string]: [] }) => void;
}

export default function PersonForm({
  initialValues,
  keyName,
  handleSaveForm,
}: Props) {
  const [form] = Form.useForm();

  const [clientType, setClientType] = useState<string[]>(
    initialValues?.map(p => (p.cnpj ? 'cnpj' : 'cpf')) || ['cpf']
  );

  const person = useCallback(() => {
    if (keyName === 'guarantors') {
      return 'Fiador(es)';
    } else if (keyName === 'locators') {
      return 'Locador(es)';
    } else if (keyName === 'tenants') {
      return 'Locatário(s)';
    } else if (keyName === 'witness') {
      return 'Testemunha(s)';
    } else {
      return false;
    }
  }, [keyName]);

  function submit(data: any) {
    const values = {
      [keyName]: data[keyName].map((p: any, index: number) => ({
        ...p,
        //cpf: clientType[index] === 'cpf' ? p.cpf : null,
        //cnpj: clientType[index] === 'cnpj' ? p.cnpj : null,
      })),
    };
    handleSaveForm(values);
    // person() &&
    //   notification.success({ message: `${person()} salvo(s) com sucesso` });
  }

  function toggleClientType(index: number, type: string) {
    const clone = JSON.parse(JSON.stringify(clientType));
    clone[index] = type;
    setClientType(clone);
  }

  function submitWithFailure() {}

  return (
    <div>
      <Form
        onFinishFailed={submitWithFailure}
        validateTrigger="onBlur"
        onFinish={submit}
        form={form}
        layout="vertical"
        initialValues={{ [keyName]: initialValues }}
        onValuesChange={() => handleSaveForm(form.getFieldsValue())}
      >
        <Form.List name={keyName}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <Row
                  gutter={8}
                  align="middle"
                  className="border bg-light p-3 mb-4"
                  key={field.name}
                >
                  <Col span={22}>
                    <Row gutter={8}>
                      <Col span={8}>
                        <Form.Item
                          name={[field.name, clientType[field.name] || 'cpf']}
                          fieldKey={[
                            field.key,
                            clientType[field.name] || 'cpf',
                          ]}
                          shouldUpdate
                          rules={[
                            {
                              required: true,
                              message: `Informe o ${
                                clientType[field.name] &&
                                clientType[field.name].toUpperCase()
                              }`,
                            },
                          ]}
                          label={
                            clientType[field.name]
                              ? clientType[field.name].toUpperCase()
                              : `CPF`
                          }
                        >
                          <MaskedInput
                            mask={
                              clientType[field.name] === 'cpf' ||
                              !clientType[field.name]
                                ? '111.111.111-11'
                                : '11.111.111/1111-11'
                            }
                            addonBefore={
                              <Select
                                onChange={(e: string) =>
                                  toggleClientType(field.name, e)
                                }
                                placeholder="Tipo do Cliente"
                                value={clientType[field.name] || 'cpf'}
                              >
                                <Select.Option value="cpf">
                                  Pessoa Fisica
                                </Select.Option>
                                <Select.Option value="cnpj">
                                  Pessoa Juridica
                                </Select.Option>
                              </Select>
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={[field.name, 'name']}
                          fieldKey={[field.key, 'name']}
                          shouldUpdate
                          rules={[
                            { required: true, message: 'Informe o nome' },
                          ]}
                          label="Nome"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={[field.name, 'last_name']}
                          fieldKey={[field.name, 'last_name']}
                          shouldUpdate
                          rules={[
                            {
                              required: clientType[field.name] === 'cpf',
                              message: 'Informe o sobrenome',
                            },
                          ]}
                          label="Sobrenome"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={4}>
                        <Form.Item
                          name={[field.name, 'status_civil']}
                          fieldKey={[field.name, 'status_civil']}
                          shouldUpdate
                          rules={[{ required: false }]}
                          label="Estado civil"
                        >
                          <Select style={{ width: '100%' }}>
                            <Select.Option value="solteiro">
                              Solteiro(a)
                            </Select.Option>
                            <Select.Option value="casado">
                              Casado(a)
                            </Select.Option>
                            <Select.Option value="divorciado">
                              Divorciado(a)
                            </Select.Option>
                            <Select.Option value="viuvo">
                              Viúvo(a)
                            </Select.Option>
                            <Select.Option value="separado">
                              Separado(a)
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[field.name, 'nationality']}
                          fieldKey={[field.name, 'nationality']}
                          label="Nacionalidade"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[field.name, 'profession']}
                          fieldKey={[field.name, 'profession']}
                          label="Profissão"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[field.name, 'ci']}
                          fieldKey={[field.name, 'ci']}
                          label="Identidade"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[field.name, 'by_proxy']}
                          fieldKey={[field.name, 'by_proxy']}
                          label="Representante"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[field.name, 'by_proxy_cpf']}
                          fieldKey={[field.name, 'by_proxy_cpf']}
                          label="CPF Representante"
                          shouldUpdate
                        >
                          <MaskedInput mask={'111.111.111-11'} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} />
                  <Col span={1} style={{ alignSelf: 'flex-start' }}>
                    <Button
                      type="dashed"
                      onClick={() => {
                        remove(field.name);
                      }}
                      block
                      icon={<CloseCircleTwoTone />}
                    ></Button>
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Adicionar
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
}
