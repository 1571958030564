import { gql } from '@apollo/client';
import { EnterprisePurchasePlan } from '../interfaces/EnterprisePurchasePlan';
import OrderService, { OrderServiceCanceled } from '../interfaces/OrderService';
import { PlanEnterpriseActivity } from '../interfaces/PlanEnterpriseActivity';

export const GET_EXTRACT_ENTERPRISE_PLAN = gql`
  query GetExtractEnterprisePlan(
    $id: String
    $dateBegin: DateTime
    $dateEnd: DateTime
    $enterpriseId: String
    $isPlanDetached: Boolean
    $isPlan: Boolean
    $isConsumption: Boolean
  ) {
    getExtractEnterprisePlan(
      id: $id
      dateBegin: $dateBegin
      dateEnd: $dateEnd
      enterpriseId: $enterpriseId
      isPlanDetached: $isPlanDetached
      isPlan: $isPlan
      isConsumption: $isConsumption
    ) {
      activity
      dateActivity
      balancePrevious
      balanceCurrent
      consumptionMetreage
      additionMetreage
      decreaseMetreage
      orderService {
        id
        metreage
        date_solicitation
        number_request_internal
        user {
          name
        }
        address_inspection {
          address
          number
          complement
          zip_code
          city
          neighborhood
          state
          latitude
          longitude
        }
        inspection {
          inspector {
            user {
              name
            }
          }
        }
      }
      orderServiceCanceled {
        id
        description
        isGenerateConsumption
        date_canceled
        orderService {
          id
          metreage
          date_solicitation
          number_request_internal
          address_inspection {
            address
            number
            complement
            zip_code
            city
            neighborhood
            state
            latitude
            longitude
          }
          inspection {
            inspector {
              user {
                name
              }
            }
          }
        }
      }
      planEnterpriseActiviy {
        id
        operation
        dateActivity
        metreage
        description
        user {
          name
        }
        planEnterprise {
          id
          balance_metreage
          price_plan
          date_expire
          dateChangePlan
          balance_previous
        }
      }
      enterprisePurchasePlan {
        id
        metreage
        balancePrevious
        datePurchase
        description
      }
    }
  }
`;

export type RecordActivityEnterprisePlan = {
  id: string;
  activity: string; // Se é consumo, cancelamento, adição ou remoção de metragem
  dateActivity: string;
  consumptionMetreage?: number;
  additionMetreage?: number;
  decreaseMetreage?: number;
  orderService?: OrderService;
  orderServiceCanceled?: OrderServiceCanceled;
  planEnterpriseActiviy?: PlanEnterpriseActivity;
  enterprisePurchasePlan?: EnterprisePurchasePlan;
};

export type GetExtractEnterprisePlan = {
  getExtractEnterprisePlan: RecordActivityEnterprisePlan[];
};

export type GetExtractEnterprisePlanVariables = {
  enterpriseId: string;
  dateBegin?: string;
  dateEnd?: string;
  isConsumption?: boolean;
  isPlanDetached?: boolean;
  isPlan?: boolean;
};
